

import { Component, Prop, Vue } from 'vue-property-decorator';
import RatesPopup from '@/modules/rates/components/rates-popup.vue';
import RatesDayPopup from '@/modules/common/modules/rates/components/common-popup/rates-day-popup.vue';

@Component({
    components: {
        RatesPopup,
        RatesDayPopup,
    },
})
export default class DayRateModalPage extends Vue {
    @Prop({
        type: Number,
    })
    hotelId!: number | undefined;

    @Prop({
        type: String,
    })
    compsetId!: string | undefined;
}
