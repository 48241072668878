
import { Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import ClipText from '@/modules/common/filters/clip-text.filter';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../../rates-analysis-filters.service';
import type { HotelTableData } from '../../interfaces/hotel-popup-table-data.interface';

@Component({
    extends: DayTooltipTemplate,
    filters: {
        ClipText(value: string) {
            return ClipText(value, 20);
        },
    },
})
export default class RatesAnalysisPreviewTooltip extends DayTooltipTemplate {
    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Prop({
        type: Object,
    })
    private rowData!: HotelTableData;

    public get hotelName() {
        if (!this.rowData) return '';

        return this.rowData.hotelName;
    }

    public get isActive() {
        return !!this.focusElement && !!this.rowData;
    }

    public get mainFilter() {
        return this.ratesAnalysisFiltersService.mainFilterLabel;
    }

    public get compareFilter() {
        return this.ratesAnalysisFiltersService.comparisonValues[0].name;
    }
}
