
import { Vue, Component, Prop } from 'vue-property-decorator';
import type { HotelTableData } from '../../interfaces/hotel-popup-table-data.interface';

@Component({})
export default class ComparePriceLabel extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    cellData!: HotelTableData;

    @Prop({
        type: Number,
        required: true,
    })
    documentIndex!: number;

    get price() {
        return String(this.cellData.analysisPrice?.[this.documentIndex] || '');
    }

    get isValidPrice() {
        return !!this.price.match(/[0-9]+/);
    }

    get roomName() {
        return this.cellData.analysisRooms?.[this.documentIndex];
    }

    get isMainRoomDifferent() {
        return this.roomName && this.roomName !== this.cellData.roomName;
    }
}
